export const state = {
    storeInquiryFormAdults: 2,
    storeInquiryFormChildren: 0,
    storeInquiryFormChildrenAges: [],
    storeInquiryFormPets: false,
    storeInquiryFormStartDate: null,
    storeInquiryFormEndDate: null,
    storeInquiryFormSpinnerActive: false
}

export const mutations = {
    SET_INQUIRY_FORM_ADULTS(state, value) {
        state.storeInquiryFormAdults = value;
        //console.log('Store Inquiry Form Adults: ' + state.storeInquiryFormAdults);
    },
    SET_INQUIRY_FORM_CHILDREN(state, value) {
        state.storeInquiryFormChildren = value;
        //console.log('Store Inquiry Form Children: ' + state.storeInquiryFormChildren);
    },
    SET_INQUIRY_FORM_CHILDREN_AGES(state, value) {
        state.storeInquiryFormChildrenAges = [];
        let valueSplit = value.split(',');
        for (let i = 0; i < value.split(',').length; i++) {
            state.storeInquiryFormChildrenAges.push(valueSplit[i]);
        }
        //console.log('Store Inquiry Form Children Ages set: ' + state.storeInquiryFormChildrenAges);
    },
    UPDATE_INQUIRY_FORM_CHILDREN_AGES(state, value) {
        let tempValue= state.storeInquiryFormChildrenAges;
        state.storeInquiryFormChildrenAges = [];
        state.storeInquiryFormChildrenAges = tempValue;
        state.storeInquiryFormChildrenAges[value.index] = value.alter;
        //console.log('Store Inquiry Form Children Ages updated: ' + state.storeInquiryFormChildrenAges);
    },
    CHECK_INQUIRY_FORM_CHILDREN_AGES(state, value) {
        let countChildren = value;
        let countChildrenAges = state.storeInquiryFormChildrenAges.length;
        if (countChildren < countChildrenAges) {
            let diff = countChildrenAges - countChildren;
            for (let i = 0; i < diff; i++) {
                state.storeInquiryFormChildrenAges.pop();
            }
        }
        //console.log('Store Check Inquiry Form Children Ages: ' + state.storeInquiryFormChildren + ' / ' + state.storeInquiryFormChildrenAges);
    },
    SET_INQUIRY_FORM_PETS(state, value) {
        state.storeInquiryFormPets = value;
        //console.log('Store Inquiry Form Pets: ' + state.storeInquiryFormPets);
    },
    SET_INQUIRY_FORM_START_DATE(state, value) {
        state.storeInquiryFormStartDate = value;
        //console.log('Store Inquiry Form Startdate: ' + state.storeInquiryFormStartDate);
    },
    SET_INQUIRY_FORM_END_DATE(state, value) {
        state.storeInquiryFormEndDate = value;
        //console.log('Store Inquiry Form Enddate: ' + state.storeInquiryFormEndDate);
    },
    SET_INQUIRY_FORM_SPINNER_ACTIVE(state, value) {
        state.storeInquiryFormSpinnerActive = value;
        //console.log('Store Inquiry Form SpinnerActive: ' + state.storeInquiryFormSpinnerActive);
    }
}

export const actions = {
    setInquiryFormAdults(context, value) {
        context.commit('SET_INQUIRY_FORM_ADULTS', value);
    },
    setInquiryFormChildren(context, value) {
        context.commit('SET_INQUIRY_FORM_CHILDREN', value);
        context.commit('CHECK_INQUIRY_FORM_CHILDREN_AGES', value);
    },
        setInquiryFormChildrenAges(context, value) {
        context.commit('SET_INQUIRY_FORM_CHILDREN_AGES', value);
    },
    updateInquiryFormChildrenAges(context, value) {
        context.commit('UPDATE_INQUIRY_FORM_CHILDREN_AGES', value);
    },
    setInquiryFormPets(context, value) {
        context.commit('SET_INQUIRY_FORM_PETS', value);
    },
    setInquiryFormStartDate(context, value) {
        context.commit('SET_INQUIRY_FORM_START_DATE', value);
    },
    setInquiryFormEndDate(context, value) {
        context.commit('SET_INQUIRY_FORM_END_DATE', value);
    },
    setInquiryFormSpinnerActive(context, value) {
        context.commit('SET_INQUIRY_FORM_SPINNER_ACTIVE', value);
    }
}