export const state = {
    storeExposeSpinnerActive: false,
}

export const mutations = {
    SET_EXPOSE_SPINNER_ACTIVE(state, value) {
        state.storeExposeSpinnerActive = value;
        // console.log('Store ExposeSpinnerActive: ' + state.storeExposeSpinnerActive);
    },
}

export const actions = {
    setExposeSpinnerActive(context, value) {
        context.commit('SET_EXPOSE_SPINNER_ACTIVE', value);
    },
}