import Vue from 'vue'
import Vuex from 'vuex'
import * as storeCommonParams from './modules/storeCommonParams';
import * as storeSearchParams from './modules/storeSearchParams';
import * as storeSearchParamsInquiryForm from './modules/storeSearchParamsInquiryForm';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    modules: {
        storeCommonParams,
        storeSearchParams,
        storeSearchParamsInquiryForm
    }
})